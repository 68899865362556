/* Styling for Inspirational Movie Quotes demo app */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.4;
  font-size: 1.4em;
  background-color: #fcfcfc;
  color: #00008b;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: justify;
}

a {
  color: #0000ff;
  text-decoration: none;
}

.img_responsive  {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

/* CSS for footer navigation bar */
#ul-footernav {
  list-style: none;
  overflow:hidden;
  background:#eef3fc;
  margin:0;
  padding:0;
}
#ul-footernav li a {
  text-align:center;
  font-weight: bold;
  color:#2160c4;
  padding:10px;
  width:95px;
}

#ul-footernav li {
  float:left;
}

#ul-footernav li:hover {
  background:#2160c4;
  color: #ff3860;
}
#ul-footernav li:hover a {
  color: #ffffff;
}
#ul-footernav  a {
  display:block;
}

#p-navfooter {
  text-align: center
}

.footernav_outer {
  text-align: center;
}

.footernav_inner {
  display: inline-block;
}

/* Inspirational Movie Quotes */
.quoteStyle {
  font-family: 'Carter One';
}

.authorStyle {
  font-style: italic;
  font-family: 'Average Sans';
  font-weight: bold;
}
.movieName {
  font-family: 'Average Sans';
  font-weight: bold;
}

.movieImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.box4quote {
  height: auto;
  width: 90%;
}
